<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row class="mt-2">
        <v-col>
          <h2>国交省登録情報 事業者データ</h2>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="2">
          <v-btn 
            class=""
            color="primary"
            width="200"
            :disabled="loading"
            link :to="'/mlit_data_list'">キャンセル</v-btn>
        </v-col>
        <v-col v-if="mode != 'edit'" cols="3" sm="3" md="3" lg="2">
          <v-btn
            class=""
            color="secondary"
            width="200"
            :disabled="loading || !(inputData.length > 0)"
            @click="deleteData()"
          >
            <v-icon :small="true">mdi-delete</v-icon>データ削除
          </v-btn>
        </v-col>
        <v-col v-if="mode == 'edit'" cols="3" sm="3" md="3" lg="2">
          <v-btn
            class=""
            color="primary"
            width="200"
            :disabled="loading || !(inputData.length > 0)"
            @click="goto_createDocument(mlitData.sid)"
          >
            <v-icon :small="true">mdi-file-document-plus-outline</v-icon>資料作成
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

    <v-container>
      <v-row>
        <v-col cols="auto" class="pt-0 pb-0 mb-n2">{{mlitData.input_date | formatDate('YYYY年MM月DD日時点')}}</v-col>
        <v-col cols="auto" class="pt-0 pb-0 mb-n2">件数 {{inputData.length}} 件</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="inputData"
            :loading="loading"
            :dense='true'
            :items-per-page="100"
            :mobile-breakpoint="0"
            :disable-pagination='false'
            :hide-default-header='false'
            :hide-default-footer='false'
            :footer-props="{ 'items-per-page-options': [100, 150, 200] }"
            :fixed-header='true'
            :disable-sort='false'
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border no-hover-effect"
            height="calc(100vh - 250px)"
          >
            <template v-slot:body="{items}">
              <tbody>
                <tr v-for="(item, index) in items" 
                  :key="index" 
                  :class="['tr-data', 'enable']" 
                  @dblclick="openEditItemDialog(item, index)">
                  <td :class="['right']">
                    {{item.manage_no}}
                  </td>
                  <td :class="['right']">
                    {{item.office_mlit}}
                  </td>
                  <td :class="['right']">
                    {{item.office_mlit_branch}}
                  </td>
                  <td :class="['right']">
                    {{item.office_pref}}
                  </td>
                  <td :class="['right']">
                    {{item.ticket}}
                  </td>
                  <td :class="['right']">
                    {{item.regist_no}}
                  </td>
                  <td :class="[]">
                    {{item.company_name}}
                  </td>
                  <td :class="['right']">
                    {{item.capital | numberFormat}}
                  </td>
                  <td :class="['right']">
                    {{item.office_main}}
                  </td>
                  <td :class="['right']">
                    {{item.office_main_mlit}}
                  </td>
                  <td :class="['right']">
                    {{item.office_no}}
                  </td>
                  <td :class="[]">
                    {{item.office_name}}
                  </td>
                  <td :class="[]">
                    {{item.office_address}}
                  </td>
                  <td :class="['right']">
                    {{item.warehouse_no}}
                  </td>
                  <td :class="[]">
                    {{item.warehouse_name}}
                  </td>
                  <td :class="['right']">
                    {{item.installation_date | formatDate}}
                  </td>
                  <td :class="['right']">
                    {{item.warehouse_mlit}}
                  </td>
                  <td :class="['right']">
                    {{item.warehouse_mlit_branch}}
                  </td>
                  <td :class="['right']">
                    {{item.warehouse_pref}}
                  </td>
                  <td :class="['right']">
                    {{item.branch}}
                  </td>
                  <td :class="[]">
                    {{item.branch_name}}
                  </td>
                  <td :class="['right']">
                    {{item.type}}
                  </td>
                  <td :class="['right']">
                    {{item.volume | numberFormat}}
                  </td>
                </tr>
              </tbody>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!------------------------------------------------------------------------>
    <!-- 事業所データ編集ダイアログ -->
    <v-dialog v-model="dialog" max-width="1080" persistent>
      <div class="">
        <v-card class="">
          <v-container>
            <v-form ref="itemForm" lazy-validation class="mt-3" v-model="validItem">
              <v-row>
                <v-col cols="2">
                  <NumberTextField
                    label="管理番号"
                    v-model="editItem.manage_no"
                    :rules="[maxVal(99999), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="営業所管轄局"
                    v-model="editItem.office_mlit"
                    :rules="[maxVal(99), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="営業所管轄支局"
                    v-model="editItem.office_mlit_branch"
                    :rules="[maxVal(99), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="営業所都道府県"
                    v-model="editItem.office_pref"
                    :rules="[maxVal(47), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="発券区分"
                    v-model="editItem.ticket"
                    :rules="[requiredIf(() => !editItem.disable), maxVal(2), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="登録番号"
                    v-model="editItem.regist_no"
                    :rules="[requiredIf(() => !editItem.disable), maxVal(99999), minVal(0), checkUniqueItem(editItemIndex)]"
                    dense
                    :toLocaleFlg="false"
                    :changeEvt="()=>{validationItem()}"
                  ></NumberTextField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="氏名又は名称"
                    v-model="editItem.company_name"
                    :rules="[]"
                    dense
                    :maxLength="100"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <NumberTextField
                    label="資本金又は出資の総額"
                    v-model="editItem.capital"
                    :rules="[maxValLocaleJP(999999999), minValLocaleJP(0)]"
                    dense
                  ></NumberTextField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <NumberTextField
                    label="主たる営業所"
                    v-model="editItem.office_main"
                    :readonly="!editMode"
                    :rules="[maxVal(99), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="主たる営業所の管轄局"
                    v-model="editItem.office_main_mlit"
                    :rules="[maxVal(99), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="営業所番号"
                    v-model="editItem.office_no"
                    :rules="[requiredIf(() => !editItem.disable), maxVal(999), minVal(0), checkUniqueItem(editItemIndex)]"
                    dense
                    :toLocaleFlg="false"
                    :changeEvt="()=>{validationItem()}"
                  ></NumberTextField>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="営業所名称"
                    v-model="editItem.office_name"
                    :rules="[]"
                    dense
                    :maxLength="100"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="営業所住所"
                    v-model="editItem.office_address"
                    :rules="[]"
                    dense
                    :maxLength="100"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <NumberTextField
                    label="倉庫番号"
                    v-model="editItem.warehouse_no"
                    :rules="[requiredIf(() => !editItem.disable), maxVal(999), minVal(0), checkUniqueItem(editItemIndex)]"
                    dense
                    :toLocaleFlg="false"
                    :changeEvt="()=>{validationItem()}"
                  ></NumberTextField>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    label="倉庫名称"
                    v-model="editItem.warehouse_name"
                    :rules="[]"
                    dense
                    :maxLength="100"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <DatePicker
                    label="設置年月日"
                    v-model="editItem.installation_date"
                    format="YYYY/MM/DD"
                    selectType="date"
                    :rules="[]"
                    :clearable="true"
                    dense
                  ></DatePicker>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="倉庫管轄局"
                    v-model="editItem.warehouse_mlit"
                    :rules="[maxVal(99), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="倉庫管轄支局"
                    v-model="editItem.warehouse_mlit_branch"
                    :rules="[maxVal(99), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="倉庫都道府県"
                    v-model="editItem.warehouse_pref"
                    :rules="[maxVal(47), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="区画番号"
                    v-model="editItem.branch"
                    :rules="[requiredIf(() => !editItem.disable), maxVal(99), minVal(0), checkUniqueItem(editItemIndex)]"
                    dense
                    :toLocaleFlg="false"
                    :changeEvt="()=>{validationItem()}"
                  ></NumberTextField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    label="区画名称"
                    v-model="editItem.branch_name"
                    :rules="[]"
                    dense
                    :maxLength="100"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="種別"
                    v-model="editItem.type"
                    :rules="[maxVal(99), minVal(0)]"
                    dense
                    :toLocaleFlg="false"
                  ></NumberTextField>
                </v-col>
                <v-col cols="2">
                  <NumberTextField
                    label="面積"
                    v-model="editItem.volume"
                    :rules="[maxValLocaleJP(999999999), minValLocaleJP(0)]"
                    dense
                  ></NumberTextField>
                </v-col>
              </v-row>
            </v-form>
            <v-row justify="end">
              <v-col class="shrink">
                <v-btn @click="updateItem" class="btn_font primary" :disabled="loading">保存</v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="deleteItem" color="btn_font secondary" :disabled="loading">削除</v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="closeDialog" class="btn_font primary" :disabled="loading">キャンセル</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </v-dialog>

    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5 action-message">{{actionMessage}}</h3> 
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>

  </v-form>

</template>

<script>
import Spinner from 'vue-simple-spinner'

import Debug from '../../Lib/Debug'
import Const from '../../mixins/const';
import Message from "../../Lib/Message";

import DatePicker from "../common/DatePicker.vue";
import NumberTextField from "../common/Number_text_field.vue";

import MlitOffice from "../../model/mlitOffice";

export default {
  components: {
    DatePicker,
    NumberTextField,
    Spinner
  },

  mixins: [Const],

  data: () => ({
    valid : true,
    validItem: true,
    loading: false,

    mode: null,

    // 国交省登録情報ID
    mlitDataSid: null,

    // 検索条件
    searchCondition: {
      screenType: 'MlitOfficeList',
      mlitDataSid: null,
    },

    // データ編集フラグ
    editMode: false,

    // テーブルヘッダー
    headers: [
        { text: '管理番号', align: 'center', sortable: true, value: 'manage_no', width: 110 },
        { text: '営業所管轄局', align: 'center', sortable: true, value: 'office_mlit', width: 110 },
        { text: '営業所管轄支局', align: 'center', sortable: true, value: 'office_mlit_branch', width: 110 },
        { text: '営業所都道府県', align: 'center', sortable: true, value: 'office_pref', width: 110 },
        { text: '発券区分', align: 'center', sortable: true, value: 'ticket', width: 110 },
        { text: '登録番号', align: 'center', sortable: true, value: 'regist_no', width: 110 },
        { text: '氏名又は名称', align: 'center', sortable: true, value: 'company_name', width: 200 },
        { text: '資本金又は出資の総額', align: 'center', sortable: true, value: 'capital', width: 150 },
        { text: '主たる営業所', align: 'center', sortable: true, value: 'office_main', width: 110 },
        { text: '主たる営業所の管轄局', align: 'center', sortable: true, value: 'office_main_mlit', width: 140 },
        { text: '営業所番号', align: 'center', sortable: true, value: 'office_no', width: 110 },
        { text: '営業所名称', align: 'center', sortable: true, value: 'office_name', width: 250 },
        { text: '営業所住所', align: 'center', sortable: true, value: 'office_address', width: 300 },
        { text: '倉庫番号', align: 'center', sortable: true, value: 'warehouse_no', width: 110 },
        { text: '倉庫名称', align: 'center', sortable: true, value: 'warehouse_name', width: 200 },
        { text: '設置年月日', align: 'center', sortable: true, value: 'installation_date', width: 120 },
        { text: '倉庫管轄局', align: 'center', sortable: true, value: 'warehouse_mlit', width: 110 },
        { text: '倉庫管轄支局', align: 'center', sortable: true, value: 'warehouse_mlit_branch', width: 110 },
        { text: '倉庫都道府県', align: 'center', sortable: true, value: 'warehouse_pref', width: 110 },
        { text: '区画番号', align: 'center', sortable: true, value: 'branch', width: 110 },
        { text: '区画名称', align: 'center', sortable: true, value: 'branch_name', width: 200 },
        { text: '類別', align: 'center', sortable: true, value: 'type', width: 110 },
        { text: '面積', align: 'center', sortable: true, value: 'volume', width: 120 },
    ],

    // 国交省登録情報
    mlitData: {},
    // 国交省事業所一覧
    inputData: [],

    // ぐるぐるメッセージ
    actionMessage: '',

    // 重複データチェック用
    idList: [],

    // 編集ダイアログ
    dialog: false,
    // 編集ダイアログ上で編集するデータ
    editItem: {},
    editItemIndex: null,
  }),

  created: function() {
  },

  mounted : function() {
    // POSTパラメータを取得
    this.mode = this.$route.params.mode;
    this.mlitDataSid = this.$route.params.editId;

    // 検索条件
    const str = this.$store.getters.getStoreSearchCondition;
    if (str.screenType == 'MlitOfficeList') {
      this.searchCondition = str;
    } else {
      this.searchCondition.mlitDataSid = this.mlitDataSid;
    }

    this.loadList();
  },

  methods: {
    /** ダイアログ */
    openEditItemDialog(item, index) {
      if(this.mode != 'edit') {
        return;
      }
      this.dialog = true;

      this.$nextTick(function () {
        this.$refs.itemForm.resetValidation();
        this.editItem = JSON.parse(JSON.stringify(item));
        this.editItemIndex = index;
        Debug.log2('editItem:', this.editItem);
      });
    },
    async updateItem() {
      this.validationItem();

      if (!this.$refs.itemForm.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("データを保存します。よろしいですか？")) return;

      this.inputData.splice(this.editItemIndex, 1, this.editItem);

      this.loading = true;
      try {
        const res = await MlitOffice.setDataList(this.mode, this.mlitDataSid, null, this.inputData);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data.validator;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("保存しました。");
        }
      } catch (error) {
        Message.err(error, "保存できませんでした");
      }

      this.loadList();

      this.loading = false;

      this.dialog = false;
    },
    async deleteItem() {
      if (!confirm("データを削除します。よろしいですか？")) return;

      // 除外フラグを立てて保存
      this.$set(this.inputData[this.editItemIndex], "disable", true);
      
      this.loading = true;
      try {
        const res = await MlitOffice.setDataList(this.mode, this.mlitDataSid, null, this.inputData);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data.validator;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("保存しました。");
        }
      } catch (error) {
        Message.err(error, "保存できませんでした");
      }

      this.loadList();

      this.loading = false;

      this.dialog = false;
    },
    closeDialog() {
      this.dialog = false;
    },

    async goto_createDocument(editId) {
      try {
        this.actionMessage = "事業者データを作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await MlitOffice.registMlitWorkTable(this.searchCondition);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data.validator;
        
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          return;
        } else {
          alert("国交省登録情報 資料作成画面に移動します。");
          this.$router.push({name: 'MlitCreateDocument', params:{editId: editId}});
        }
      } catch (error) {
        Message.err(error, "事業者データを作成できませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    async loadList() {
      Debug.log("function[loadList]");

      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      Debug.log(this.searchCondition);

      this.loading = true;
      // DBから一覧取得
      MlitOffice.search(this.searchCondition)
        .then(res => {
          if (res && res.data) {
            this.mlitData = res.data.mlitData;
            this.inputData = res.data.mlitOffice;
            Debug.log(this.inputData);
          }
        })
        .catch((err) => {
          Message.err(err, "国交省事業所一覧を取得できませんでした");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 削除
    async deleteData() {
      if (!confirm("すべてのデータを削除します。よろしいですか？")) return;

      this.loading = true;
      try {
        const res = await MlitOffice.delDataList(this.mlitDataSid);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("削除しました。");
          this.$router.push('/mlit_data_list');
        }
      } catch (error) {
        Message.err(error, "削除できませんでした");
      }
    },

    // unique チェック用 idListを作成
    makeIdList() {
      this.idList = this.inputData.map(value => {
        return [value.disable, value.regist_no, value.office_no, value.warehouse_no, value.branch];
      });
    },

    // 編集ダイアログ 主キーのバリデーション
    checkUniqueItem() {
      if(Object.keys(this.editItem).length <= 0) {
        return true;
      }

      if ( this.editItem.validateMsg != '') {
        return this.editItem.validateMsg;
      } else {
        return true;
      }
    },
    // 編集ダイアログ Uniqueチェック
    validationItem() {
      // id の uniqueチェック用
      this.makeIdList();

      let result = false;
      if(!this.editItem.disable) {
        for(let i=0; i < this.inputData.length; i++) {
          if(!this.inputData[i].disable) {
            if ( (i != this.editItemIndex) &&
                 (this.editItem.regist_no == this.inputData[i].regist_no) && 
                 (this.editItem.office_no == this.inputData[i].office_no) &&
                 (this.editItem.warehouse_no == this.inputData[i].warehouse_no) &&
                 (this.editItem.branch == this.inputData[i].branch)) {
              result = true;
              break;
            }
          }
        }
      }

      let msg = '';
      if (result && this.mode != 'delete') {
        msg = '重複データがあります。';
      }

      this.$set(this.editItem, 'validateMsg', msg);
    },
  },

  computed: {
  }

}
</script>


<style scoped>
.v-data-table >>> .v-data-table__wrapper th {
  padding: 0 !important;
}
.v-data-table >>> .v-data-table__wrapper td {
  padding: 0px 3px 0px 3px;
}

.v-data-table >>> .v-data-table__wrapper .v-text-field {
    padding-top: 6px;
}
.v-data-table >>> .v-data-table__wrapper .v-input .v-input__slot {
    margin-bottom: 2px;
}

/* ぐるぐる */
.action-message {
  white-space: pre-line;
}
.spinner {
  margin: 50px;
}
</style>

<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row class="mt-2">
        <v-col cols="auto">
          <h2>国交省登録情報 登録情報一覧</h2>
        </v-col>
        <v-col class="d-flex align-center" cols="6" sm="6" md="4" lg="4">
          <DatePicker
            label="作成時点"
            propClass="right-input"
            v-model="searchCondition.inputDateFrom"
            format="YYYY年MM月DD日時点"
            selectType="date"
            :rules="[]"
            :clearable="true"
            dense
            :changeEvt="loadList"
          ></DatePicker>
          ～
          <DatePicker
            label="作成時点"
            propClass="right-input"
            v-model="searchCondition.inputDateTo"
            format="YYYY年MM月DD日時点"
            selectType="date"
            :rules="[]"
            :clearable="true"
            dense
            :changeEvt="loadList"
          ></DatePicker>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn 
            class=""
            color="primary"
            width="200"
            :disabled="loading"
            @click="goto_dataInput()"><v-icon :small="true">mdi-import</v-icon>新規データ取込</v-btn>
        </v-col>
      </v-row>

    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="mlitData"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='false'
            :hide-default-footer='true'
            :fixed-header='true'
            :disable-sort='false'
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border no-hover-effect"
            height="calc(100vh - 190px)"
          >
            <template v-slot:body="{items}">
              <tbody>
                <tr v-for="(item, index) in items" :key="index" class="tr-data" >
                  <td class="right">
                    {{ item.input_date | formatDate('YYYY年MM月DD日時点') }}
                  </td>
                  <td class="right">
                    {{ item.office_num }}
                  </td>
                  <td class="right">
                    {{ item.insert | formatDate('YYYY/MM/DD HH:mm:ss') }}
                  </td>
                  <td class="right">
                    {{ item.update | formatDate('YYYY/MM/DD HH:mm:ss') }}
                  </td>
                  <td class="center">
                    <v-btn small class="primary" :height="20" :width="100" :disabled="loading" @click="goto_detail('edit', item.sid)">詳細・編集</v-btn>
                    <v-btn small class="secondary ml-1" :height="20" :width="100" :disabled="loading" @click="goto_detail('delete', item.sid)">削除</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

  </v-form>

</template>

<script>
import Debug from '../../Lib/Debug'
import Const from '../../mixins/const';
import Message from "../../Lib/Message";

import DatePicker from "../common/DatePicker.vue";

import MlitData from "../../model/mlitData";

export default {
  components: {
    DatePicker,
  },

  mixins: [Const],

  data: () => ({
    valid : true,
    loading: false,

    // ファイル ドラッグアンドドロップ
    isEnter: false,

    // 検索条件
    searchCondition: {
      inputDateFrom: null,
      inputDateTo: null
    },

    // テーブルヘッダー
    headers: [
        { text: '作成時点', align: 'center', sortable: true, value: 'input_date', width: 110 },
        { text: '登録件数', align: 'center', sortable: true, value: 'office_num', width: 110 },
        { text: '取込日', align: 'center', sortable: true, value: 'insert', width: 110 },
        { text: '更新日', align: 'center', sortable: true, value: 'update', width: 110 },
        { text: '', align: 'center', sortable: false, value: 'action', width: 120 },
    ],

    // 国交省登録情報データ
    mlitData: [],
    mokMlitData: [
      { sid: 1, input_date: '2022/07/01', office_num: 95, insert: '2022/7/3', update: '2022/7/3'},
      { sid: 2, input_date: '2022/10/01', office_num: 110, insert: '2022/10/3', update: '2022/10/3'},
      { sid: 3, input_date: '2023/03/01', office_num: 100, insert: '2023/3/3', update: '2023/3/3'},
    ],

  }),

  created: function() {
  },

  mounted : function() {
    this.loadList();
  },

  methods: {
    goto_dataInput() {
      // 新規国交省データ取込
      this.$router.push('/mlit_data_input');
    },

    goto_detail(mode, editId) {
      // 国交省登録情報 事業所一覧
      this.$router.push({name: 'MlitOfficeList', params:{mode: mode, editId: editId}});
      //this.$router.push('/mlit_office_list' + mlitDataSid);
    },

    async loadList() {
      Debug.log("function[loadList]");

      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      Debug.log(this.searchCondition);

      this.loading = true;
      // DBから一覧取得
      MlitData.search(this.searchCondition)
        .then(res => {
          if (res && res.data) {
            this.mlitData = res.data;
            Debug.log(this.mlitData);
          }
        })
        .catch((err) => {
          Message.err(err, "国交省登録情報一覧を取得できませんでした");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  computed: {
  }

}
</script>


<style scoped>
.v-data-table >>> .v-data-table__wrapper th {
  padding: 0 !important;
}
.v-data-table >>> .v-data-table__wrapper td {
  padding: 0px 3px 0px 3px;
}

.v-data-table >>> .v-data-table__wrapper .v-text-field {
    padding-top: 6px;
}
.v-data-table >>> .v-data-table__wrapper .v-input .v-input__slot {
    margin-bottom: 2px;
}
</style>
